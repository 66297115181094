import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Radar, Bar } from 'react-chartjs-2';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Title,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
} from 'chart.js';
import { HiOutlineDownload } from "react-icons/hi";

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Title,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement
);

const Dashboard = () => {
    const [totalUsers, setTotalUsers] = useState(0);
    const [totalChats, setTotalChats] = useState(0);
    const [totalMessages, setTotalMessages] = useState(0);
    const [filteredUsers, setFilteredUsers] = useState(0);
    const [filteredChats, setFilteredChats] = useState(0);
    const [filteredMessages, setFilteredMessages] = useState(0);
    const [error, setError] = useState('');

    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const fetchAllData = async () => {
        try {
            const [usersResponse, chatsResponse, messagesResponse] = await Promise.all([
                axios.get(`${process.env.REACT_APP_BACKEND_URL}/total-users`),
                axios.get(`${process.env.REACT_APP_BACKEND_URL}/total-chats`),
                axios.get(`${process.env.REACT_APP_BACKEND_URL}/total-messages`),
            ]);

            console.log('Users:', usersResponse.data);
            console.log('Chats:', chatsResponse.data);
            console.log('Messages:', messagesResponse.data);

            setTotalUsers(usersResponse.data.totalUsers);
            setTotalChats(chatsResponse.data.totalChats);
            setTotalMessages(messagesResponse.data.totalMessages);

            // Additional logging to check state values
            console.log('Total Users:', usersResponse.data.totalUsers);
            console.log('Total Chats:', chatsResponse.data.totalChats);
            console.log('Total Messages:', messagesResponse.data.totalMessages);
        } catch (err) {
            console.error('Error fetching data:', err);
            setError('Error fetching data.');
        }
    };

    const fetchDataByDateRange = async () => {
        try {
            if (!startDate || !endDate) {
                setFilteredUsers(totalUsers);
                setFilteredChats(totalChats);
                setFilteredMessages(totalMessages);
                return;
            }

            // const formattedStartDate = startDate.toISOString().split('T')[0];
            const formattedStartDate = startDate.toLocaleDateString('en-CA');
            const formattedEndDate = endDate.toLocaleDateString('en-CA');
            console.log(formattedStartDate, "start")
            console.log(formattedEndDate, "end")
            const [usersResponse, chatsResponse, messagesResponse] = await Promise.all([
                axios.get(`${process.env.REACT_APP_BACKEND_URL}/total-users?startDate=${formattedStartDate}&endDate=${formattedEndDate}`),
                axios.get(`${process.env.REACT_APP_BACKEND_URL}/total-chats?startDate=${formattedStartDate}&endDate=${formattedEndDate}`),
                axios.get(`${process.env.REACT_APP_BACKEND_URL}/total-messages?startDate=${formattedStartDate}&endDate=${formattedEndDate}`)
            ]);

            setFilteredUsers(usersResponse.data.totalUsers);
            setFilteredChats(chatsResponse.data.totalChats);
            setFilteredMessages(messagesResponse.data.totalMessages);
        } catch (err) {
            console.error('Error fetching data:', err);
            setError('Error fetching data.');
        }
    };

    useEffect(() => {
        fetchAllData();
    }, []);

    useEffect(() => {
        fetchDataByDateRange();
    }, [startDate, endDate]);

    const generateCSV = () => {
        const data = [
            ['Metric', 'Count'],
            ['Total Users', startDate && endDate ? filteredUsers : totalUsers],
            ['Total Chats', startDate && endDate ? filteredChats : totalChats],
            ['Total Messages', startDate && endDate ? filteredMessages : totalMessages],
        ];

        const csvContent = data.map(e => e.join(",")).join("\n");
        return new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    };

    const downloadCSV = () => {
        const blob = generateCSV();
        const url = URL.createObjectURL(blob);

        const formattedStartDate = startDate ? startDate.toISOString().split('T')[0] : 'N/A';
        const formattedEndDate = endDate ? endDate.toISOString().split('T')[0] : 'N/A';

        const filename = `analytics_${formattedStartDate}_to_${formattedEndDate}.csv`.replace(/:/g, '-');

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const barData = {
        labels: ['Total Users', 'Total Chats', 'Total Messages'],
        datasets: [
            {
                label: 'Count',
                data: [
                    startDate && endDate ? filteredUsers : totalUsers,
                    startDate && endDate ? filteredChats : totalChats,
                    startDate && endDate ? filteredMessages : totalMessages,
                ],
                backgroundColor: [
                    '#3B82F6',  // Tailwind blue-500
                    '#22C55E',   // Tailwind green-500
                    '#A855F7',   // Tailwind purple-500
                ],
                borderColor: [
                    '#3B82F6',      // Border color for Total Users (blue-500)
                    '#22C55E',      // Border color for Total Chats (green-500)
                    '#A855F7',      // Border color for Total Messages (purple-500)
                ],
                borderWidth: 2,
                barThickness: 40,
            },
        ],
    };

    const radarData = {
        labels: ['Total Users', 'Total Chats', 'Total Messages'],
        datasets: [
            {
                label: 'Count',
                data: [
                    startDate && endDate ? filteredUsers : totalUsers,
                    startDate && endDate ? filteredChats : totalChats,
                    startDate && endDate ? filteredMessages : totalMessages,
                ],
                backgroundColor: 'rgba(153, 102, 255, 0.2)',  // Light purple fill
                borderColor: 'rgba(153, 102, 255, 1)',        // Darker purple border
                borderWidth: 1,
                fill: true,
            },
        ],
    };

    const barOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Total Users, Chats, and Messages (Bar Chart)',
            },
        },
    };

    const radarOptions = {
        responsive: true,
        scales: {
            r: {
                suggestedMin: 0,
                suggestedMax: Math.max(totalUsers, totalChats, totalMessages) + 10, // Adjust for padding
            },
        },
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Total Users, Chats, and Messages (Radar Chart)',
            },
        },
    };

    return (
        <div className="p-2">
            <div className="flex justify-between items-center mb-6">
                <div>
                    <h1 className="text-3xl font-bold mb-2">Home</h1>
                    <p className="text-gray-500">Welcome! Take a coffee and sit with your analysis...</p>
                </div>
                <div className='flex flex-col items-end'>
                    <div className="flex items-center mb-2">
                        <p className="text-black text-sm font-semibold mr-2">Select Date Range</p>
                        <button onClick={downloadCSV}>
                            <HiOutlineDownload />
                        </button>
                    </div>
                    <div className="flex space-x-4">
                        <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                                setDateRange(update);
                            }}
                            isClearable={true}
                            dateFormat="yyyy-MM-dd"
                            className="p-2 border border-gray-300 rounded w-[200px] h-[30px] text-sm"
                            placeholderText="Select a date range"
                        />
                    </div>
                </div>
            </div>
            <div className="mb-4">
                <p className="text-xs text-gray-500 mt-1">Recommended: Refresh your web browser once you reach the page to ensure accurate data.</p>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-4 gap-4 mb-8">
                <div className="p-4 border-l-4 border-blue-500 bg-white/10 backdrop-blur-lg shadow-lg rounded-md">
                    <h2 className="text-2xl font-bold">{startDate && endDate ? filteredUsers : totalUsers}</h2>
                    <p className="text-gray-500">Total Users</p>
                </div>
                <div className="p-4 border-l-4 border-green-500 bg-white/20 backdrop-blur-lg shadow-lg rounded-md">
                    <h2 className="text-2xl font-bold">{startDate && endDate ? filteredChats : totalChats}</h2>
                    <p className="text-gray-500">Total Chats</p>
                </div>
                <div className="p-4 border-l-4 border-purple-500 bg-white/20 backdrop-blur-lg shadow-lg rounded-md">
                    <h2 className="text-2xl font-bold">{startDate && endDate ? filteredMessages : totalMessages}</h2>
                    <p className="text-gray-500">Total Messages</p>
                </div>
            </div>

            {error && <p className="text-purple-500">{error}</p>}

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div className="bg-white p-4 rounded-md shadow w-full lg:mb-0">
                    <Bar options={barOptions} data={barData} />
                </div>
                <div className="flex bg-white justify-center items-center p-4 rounded-md shadow w-full lg:mb-0 h-[400px]">
                    <div className='w-[450px] h-[450px] mt-16'>
                        <Radar options={radarOptions} data={radarData} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
